import { IAppConfig } from "./IAppConfig";

const sensibleDefaults:{ [k:string]:any } = {
	TUTORIAL_URL:"https://envalys.is/vrpsychlab",
	API_URL:"http://localhost:5000",
};

export const getConfig = ():IAppConfig => {
	return {
		apiURL:loadVar("API_URL"),
		tutorialURL:loadVar("TUTORIAL_URL"),
	}
};

const getWindow = () => (window as any).config || {};
const getEnv = () => (process as any).env || {};

const loadVar = (name: string) => {
	const v = getEnv()[`VUE_APP_${name}`] || getWindow()[name];
	if (!v && sensibleDefaults[name] === undefined) {
		throw new Error(`Config: Unset value for '${name}'.`);
	}
	return v;
};