import { defineComponent, PropType, reactive, computed, ref, inject, ComputedRef } from "@vue/composition-api";
import * as SessionAPI from "@/services/api/sessions";
import { Session } from "@/psychlab/types";
import { openRouterTab } from "@/utils/dom";
import { StudyContext } from "@/components.studies/manage-study";
import { pageLimitOptions, tableFields } from "./config";
import { useTranslate } from "@lang";
import { Pagination, Duration, Date as DisplayDate } from "@ui";

type Dictionary<T> = { [k:string]:T };

export const ListStudySessions = defineComponent({

	props:{
		studyId:{
			type:String,
			required:true
		},
		query:{
			type:Object as PropType<Dictionary<any>|null>,
			default:null
		},
		queryFn:{
			type:Function as PropType<QueryFn>,
			default:() => { return {}; }
		},
		sessionRoute:{
			type:String,
			required:true
		},
		showFilter:{
			type:Boolean,
			default:true
		},
		hiddenFields:{
			type:Array as PropType<string[]>,
			default:() => []
		}
	},
	components:{
		Pagination,
		Duration,
		DisplayDate,
	},
	setup(props, context){

		const filterDropdown = ref<Dropdown>();
		const table = ref<Table>();

		const pagination = reactive<IPagination>({
			currentPage:1,
			rows:0,
			perPage:20
		});

		const { translate } = useTranslate();

		
		

		const studyContext = inject<ComputedRef<StudyContext>>("studyContext");

			if(!studyContext){ throw Error("Session List: Study context missing!"); }

			const {
				channels,
			} = studyContext.value;

		const filterSettings = reactive<Dictionary<FilterSetting>>({
			"progress":{
				enabled: false,
				value: 0
			},
			"done":{
				enabled: false,
				value: true
			}
		});

		const paginationLabel = computed(() => {
			
			return translate("label.paginationSelection")
			.replace("$min", paginationLabels.value.min.toString())
			.replace("$max", paginationLabels.value.max.toString())
			.replace("$rows", pagination.rows.toString())


		});

		const showOptionalProgress = computed(() => {
			return channels.value.findIndex(c => c.optional) > -1;
		});

		const appliedFilters = ref<Dictionary<boolean>>({});

		const limitOptions = computed(() => {
			return pageLimitOptions.map(o => {
				return { value:o, text:o.toString() }
			})
		});

		const filterCount = computed(() => {
			let count = 0;
			Object.keys(appliedFilters.value).forEach(k => count++);
			return count;
		});

		const fields = computed(() => {

			const hfs = [ ...props.hiddenFields ];

			if(!showOptionalProgress.value){
				hfs.push("optionalProgress")
			};

			return tableFields.filter(f => hfs.indexOf(f.key) < 0);
		});

		const paginationLabels = computed(() => {
			let i = pagination.currentPage - 1;
			const min = i * pagination.perPage + 1;
			const max = clamp(i * pagination.perPage + pagination.perPage, 0, pagination.rows);
			return { min, max };
		});

		const applyFilter = () => {
			if(!filterDropdown.value){ return; }
			filterDropdown.value.hide(true)
			appliedFilters.value = {};
	
			Object.keys(filterSettings)
			.forEach(k => {
				const fs = filterSettings[k];
				if(fs.enabled){
					appliedFilters.value[k] = true;
					appliedFilters.value = JSON.parse(JSON.stringify(appliedFilters.value))
				}
			});
			refreshSessions();
		}

		const clearFilter = () => {
			appliedFilters.value = {};
			refreshSessions();
		}

		const sessionProvider = async(ctx:any) => {
			try {
				const { currentPage, perPage, sortBy, sortDesc } = ctx;
				const sort = `${sortDesc ? '-' : ''}${sortBy}`;
	
				const fields = [
					"completed",
					"created",
					"data",
					"done",
					"duration",
					"expires",
					"group",
					"progress",
					"optionalProgress",
					"state",
					"log.request.headers"
				];
				const q:Dictionary<any> = {
					...{ "group":props.studyId, limit: perPage, page:currentPage, sort },
					...(props.query ? props.query : (props.queryFn ? props.queryFn() : {})),
					select: fields.join(" ")
				};
				if(appliedFilters.value["progress"]){ q["progress[gte]"] = filterSettings["progress"].value; }
				if(appliedFilters.value["done"]){ q["done"] = filterSettings["done"].value; }
				const { sessions, count } = await SessionAPI.loadSessions(q);
				pagination.rows = count;

				return sessions;
			} catch (error) {
				return []
			}
		}

		const refreshSessions = () => {
			if(table.value){
				table.value.refresh();
			}
		}

		const getProgressLabel = (progress:number) => {
			return `${Math.trunc(progress)}%`;
		}

		const getProgressVariant = (s:any) => {
			switch(computeSessionRunState(s)){
				case "running": return "warning";
				case "done": return "success";
				case "expired": return "danger";
			}
		};
	
		const computeRunState = (s:any) => {
			return computeSessionRunState(s);
		}
	
		const getProgressIcon = (s:any) => {
			switch(computeSessionRunState(s)){
				case "running": return "mdi.clock";
				case "done": return "mdi.clock-check";
				case "expired": return "mdi.clock-alert ";
			}
		};
	
		const getSessionRoute = (sessionId:string) => {
			const studyId = context.root.$route.params["groupId"];
			return {
				name: props.sessionRoute,
				params:{
					sessionId,
					studyId,
				}
			}
		};

		const openRoute = (sid:string, tab:boolean = false) => {
			const r = getSessionRoute(sid);
			if(tab){
				openRouterTab(context.root.$router, r.name, r.params);
			}
			else {
				context.root.$router.push(r);
			}
		};

		return {
			filterSettings,
			filterDropdown,
			table,
			pagination,
			limitOptions,
			filterCount,
			fields,
			paginationLabels,
			paginationLabel,
			sessionProvider,
			refreshSessions,
			applyFilter,
			clearFilter,
			getSessionRoute,
			getProgressIcon,
			computeRunState,
			getProgressVariant,
			getProgressLabel,
			openRoute,
			translate,
		};
	}

});


const clamp = (v:number, min:number, max:number) => v < min ? min : (v > max ? max : v);

const isSessionExpired = (session:Session) => {
	if(!session.expires){ return true; } // missing expiration
	if((session.expires - Date.now()) < 0){ return true; }	
	return false;
};

const computeSessionRunState = (session:Session) => {
	let expired = isSessionExpired(session);
	if(session.done){ return "done"; }
	else if(!session.done && expired){ return "expired"; }
	return "running";
}
interface IPagination {
	currentPage:number,
	rows:number,
	perPage:number
}

type Dropdown = {
	hide(v:any):void
};

type FilterSetting = {
	enabled:boolean,
	value:any
};

type QueryFn = () => Dictionary<any>;

type Table = {
	refresh():void
};