import { defineComponent, PropType, computed } from "@vue/composition-api";
import { AbsBox } from "../@ui";

const viewerPath = "https://panorama.viewer.envalys.is/video?q=$PAYLOAD";

export const VideoPanorama = defineComponent({
	emits:[ "loaded" ],
	props:{
		config:{
			type:Object as PropType<{
				sources:string[];
			}>,
			required:true,
		},
	},
	setup(props, ctx){

		const url = computed(() => {
			const pl = encodeURIComponent(Buffer.from(JSON.stringify({
				src:props.config.sources,
			})).toString("base64"));
			return viewerPath.replace("$PAYLOAD", pl); 
		});

		const handleLoaded = () => {
			ctx.emit("loaded");
		};

		return {
			url,
			handleLoaded,
		};
	},
	render(){
		return (
			<AbsBox>
				<iframe
				src={ this.url }
				class="w-100 h-100 border-0"
				allowfullscreen
				eager
				v-on:load={ this.handleLoaded }
				/>
			</AbsBox>
		);
	}
});

// const frameFlags = [
// 	"allowFullscreen",
// 	"eager"
// ];